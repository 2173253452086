<template>
  <div>
    <div
      class="text-h6 px-2 mb-2"
      >
      Variaciones de precio
    </div>
    <v-alert
      outlined
      type="info"
      >
      La información de estas gráficas puede demorar en actualizarse para minimizar el riesgo de manipulación de precios.
    </v-alert>
    <v-card
      outlined
      rounded="lg"
      class="mb-2"
      >
      <v-card-text
        class="px-2"
        >
        <v-sparkline
          :smooth="5"
          :gradient="['#f72047', '#ffd200', '#1feaea']"
          stroke-linecap="round"
          :labels="labels"
          :value="aPrices"
          line-width="2"
          padding="16"
          ></v-sparkline>
      </v-card-text>
    </v-card>
    <template
      v-for="price in prices"
      >
      <price
        :price="price"
        class="mb-2"
        ></price>
    </template>
  </div>
</template>

<script>
import Price from './Price'
export default {
  data () {
    return {
      prices: []
    }
  },

  components: { 
    Price
  },

  props: {
    product: {
      required: true,
      type: Object
    }
  },

  computed: {
    labels () {
      return this.prices.map( price => price.date.toLocaleDateString() ).reverse()
    },

    aPrices () {
      return this.prices.map( price => parseFloat(price.price) ).reverse()
    }
  },

  created () {
    var last = 0
    this.product.priceTrackers.forEach( price => {
      this.prices.push({
        price: parseFloat(price.amount),
        up: price.amount > last,
        date: new Date(price.createdAt)
      })
      last = price.amount
    })
  }
}
</script>
