<template>
  <div>
    <v-card
      outlined
      rounded="lg"
      >
      <v-card-text
        class="px-2"
        >
        <div
          class="d-flex justify-space-between align-center"
          >
          <div
            class="text-subtitle-2 d-flex"
            >
            <span>
            </span>
              {{ displayPrice(price.price) }}
            <v-icon
              :color="price.up ? 'error' : 'success'"
              >
              {{ price.up ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </div>
          <div
            class="caption"
            >
            {{ price.date.toLocaleString() }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      required: true,
      type: Object
    }
  },

  methods: {
    displayPrice (price) {
      return (parseFloat(price)).toLocaleString('es-CL', {
        style: 'currency',
        currency: 'CLP',
        minimumFractionDigits: 2,
      });
    }
  }
}
</script>
